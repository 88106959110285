import dayjs from 'dayjs'
import { notifyHttpClient } from './notify-http-client'
import { iosApp } from 'src/utils/clients/native/ios-app'
import { getSlug } from 'src/utils/get-slug'
import { NotificationHistoryResponse } from './models/notification-history-response'
import { KyResponse } from 'ky'
import { PushRecipient } from 'src/api/notify-service/models/push-recipient'
import getClient from 'src/utils/clients/get-client'

export const notifyService = {
  register,
  getNotificationHistory,
  markNotificationsAsRead,
  unregister,
  subscribe,
  unsubscribe,
}

async function register({ groupId, registrantId, token }: PushRecipient): Promise<unknown> {
  return notifyHttpClient.put(`v1/notificationGroups/${groupId}/registrants/${registrantId}`, {
    slug: registrantId,
    identifier: token,
    variant: getSlug(),
    service: getPushService(),
    driverConfiguration: await getDriverConfiguration(),
  })
}

async function markNotificationsAsRead(
  groupId: string,
  notificationIds: string[]
): Promise<unknown> {
  return notifyHttpClient.put(`v1/notificationGroups/${groupId}/notifications/read`, {
    ids: notificationIds,
  })
}

async function getNotificationHistory(
  groupId: string,
  slug: string
): Promise<NotificationHistoryResponse> {
  // the type local is defined, but typescript can't see it
  // @ts-ignore
  const ninetyDaysAgo = dayjs().subtract(90, 'days').local().format()

  return notifyHttpClient.get(
    `v1/notificationGroups/${groupId}/notifications?fromDate=${ninetyDaysAgo}&registrantVariant=${slug}`
  )
}

async function unregister(groupId: string, registrantId: string): Promise<KyResponse> {
  return notifyHttpClient.put(`v1/notificationGroups/${groupId}/registrants/${registrantId}`, {
    slug: registrantId,
    identifier: 'push-notifications-disabled',
    variant: getSlug(),
    service: getPushService(),
  })
}

async function subscribe(
  groupId: string,
  registrantId: string,
  subscriptions: string[]
): Promise<unknown> {
  return notifyHttpClient.put(
    `v1/notificationGroups/${groupId}/registrants/${registrantId}/subscriptions`,
    {
      subscriptions,
    }
  )
}

async function unsubscribe(
  groupId: string,
  registrantId: string,
  subscriptions: string[]
): Promise<unknown> {
  return notifyHttpClient.delete(
    `v1/notificationGroups/${groupId}/registrants/${registrantId}/subscriptions`,
    {
      subscriptions,
    }
  )
}

// Helpers
function getPushService(): string | undefined {
  switch (getClient().platform) {
    case 'android':
      return 'fcm'
    case 'ios':
      return 'apns'
    case 'web':
      return undefined
  }
}

async function getDriverConfiguration(): Promise<{ sandbox: boolean } | undefined> {
  if (getClient().isIos) {
    return { sandbox: await iosApp.isDebugBuild() }
  } else {
    return undefined
  }
}
