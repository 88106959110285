import { aggregatorClient } from 'src/api/aggregator/client'

export const businessPagesBaseUrl = 'api/aggregator/businesspages'

export const businessLocationRequests = {
  // Grower
  getBusinessLocations: (body?: BusinessLocationsRequest) =>
    aggregatorClient
      .post(`${businessPagesBaseUrl}/v1/GetBusinessLocations`, { json: body })
      .json<BusinessLocationsResponse>(),
  getBusinessLocationDetails: (body: BusinessLocationDetailsRequest) =>
    aggregatorClient
      .post(`${businessPagesBaseUrl}/v1/GetBusinessLocationById`, { json: body })
      .json<BusinessLocationDetailsResponse>(),
  getBusinessLocationsFilters: () =>
    aggregatorClient
      .post(`${businessPagesBaseUrl}/v1/GetBusinessLocationsFilters`, { json: {} })
      .json<BusinessLocationsFiltersResponse>(),
  // Staff
  setActiveBusinessLocationsByLocationIds: (body: SetActiveBusinessLocationsByLocationIdsRequest) =>
    aggregatorClient
      .post(`${businessPagesBaseUrl}/v1/SetActiveBusinessLocationsByLocationIds`, { json: body })
      .json<SetActiveBusinessLocationsByLocationIdsResponse>(),
  staffGetBusinessLocationById: (body: StaffGetBusinessLocationByIdRequest) =>
    aggregatorClient
      .post(`${businessPagesBaseUrl}/v1/StaffGetBusinessLocationById`, { json: body })
      .json<StaffGetBusinessLocationByIdResponse>(),
  staffGetBusinessLocations: (body: StaffGetBusinessLocationsRequest) =>
    aggregatorClient
      .post(`${businessPagesBaseUrl}/v1/StaffGetBusinessLocations`, { json: body })
      .json<StaffGetBusinessLocationsResponse>(),
  updateBusinessLocation: (body: UpdateBusinessLocationRequest) =>
    aggregatorClient
      .post(`${businessPagesBaseUrl}/v1/UpdateBusinessLocation`, { json: body })
      .json<UpdateBusinessLocationResponse>(),
}
