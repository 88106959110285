import { HTTPError as KyHTTPError } from 'ky'
import { centreClient } from './client'
import { contractsRequests } from './contracts'
import { contractsRequests as contractsRequestsV3 } from './contracts-v3'
import { ticketRequests } from './tickets'
import { commodityBalanceRequests } from './commodity-balances'
import { settlementsRequests } from './settlements'
import { companiesRequests } from './companies'
import { usersRequests } from './users'
import { cashBidRequests } from './futures-service/cash-bids'
import { futuresRequests } from './futures-service/futures'
import { paymentsCompanyRequests } from './payments-service/company'
import {
  deferredPaymentsRequests,
  externalPaymentAssociations,
  historicalPaymentsRequests,
  paymentsFilterRequests,
  paymentsPaymentsRequests,
} from './payments-service/payments'
import { paymentsUsersRequests } from './payments-service/users'
import { centreCashBidRequests } from './cash-bids/cash-bids'
import { eSignRequests } from './esign'
import { prepaidRequests } from './prepaids'
import { deliveryTicketRequests } from './delivery-tickets'
import { invoiceRequests } from './invoices'
import { customEntitiesRequests } from './custom-entities'
import { analyticsRequests } from './analytics-service/analytics'
import { centreAnalyticsRequests } from './analytics'
import { workOrderRequests } from './work-orders'
import { proofOfYieldReportRequests } from 'src/api/reporting-service/reports'
import { reportingConfigRequests } from 'src/api/reporting-service/config-reports'
import { AppConfig } from './centre-service/data-models/app-config'
import { statementsRequests } from './aggregator/statements'
import { crmRequests } from './aggregator/crm'
import { accountPayableBalanceRequests } from './aggregator/account-payable-balances'
import { configRequests } from './aggregator/config'
import { elevatorsRequests } from './elevators'
import { outboundTicketsRequests } from './outbound-tickets'
import { authRequests } from './aggregator/auth'
import { businessLocationRequests } from './aggregator/business-locations'
import { locationFavoritesRequests } from 'src/api/aggregator/location-favorites'

// See: https://github.com/sindresorhus/ky/issues/210#issuecomment-558983755
export type HTTPError = KyHTTPError & {
  response: KyHTTPError['response'] & { payload: any }
}

// CENTRE REQUESTS
///////////////////////////////////////////////////////////////////////////////////////////////////

export const centre = {
  me: async () => {
    const response = await centreClient.get('api/v1/auth/me').json<{ data: Me }>()

    return response.data
  },

  config: async () => {
    const response = await centreClient.get('api/v1/app-config', {
      headers: { Accept: 'application/json' },
    })
    const body: { data: AppConfig } = await response.json()

    return body.data
  },

  reportProblem: async (body: Record<string, string>) => {
    // omitting the `.json()` bit because this API returns nothing
    return centreClient.post('api/v1/report-a-problem', { json: body })
  },

  reportFailedLogin: async (body: {
    company_id: number
    value: string
    name?: string
    location_id?: number
  }) => {
    return centreClient.post('api/v1/failed_login', { json: body })
  },

  requestDeleteAccount: ({ name, email }: { name?: string; email?: string }) => {
    return centreClient.post('api/v1/delete-account', {
      json: {
        name,
        email,
      },
    })
  },

  ...companiesRequests,
  ...ticketRequests,
  ...contractsRequests,
  ...contractsRequestsV3,
  ...commodityBalanceRequests,
  ...settlementsRequests,
  ...usersRequests,
  ...centreCashBidRequests,
  ...eSignRequests,
  ...prepaidRequests,
  ...deliveryTicketRequests,
  ...invoiceRequests,
  ...workOrderRequests,
  ...customEntitiesRequests,
  ...centreAnalyticsRequests,
  ...elevatorsRequests,
  ...outboundTicketsRequests,
}

export const futuresService = {
  ...cashBidRequests,
  ...futuresRequests,
}

export const paymentsService = {
  ...paymentsCompanyRequests,
  ...paymentsPaymentsRequests,
  ...paymentsUsersRequests,
  ...historicalPaymentsRequests,
  ...deferredPaymentsRequests,
  ...externalPaymentAssociations,
  ...paymentsFilterRequests,
}

export const analyticsService = {
  ...analyticsRequests,
}

export const reportingService = {
  ...proofOfYieldReportRequests,
  ...reportingConfigRequests,
}

export const aggregatorService = {
  ...locationFavoritesRequests,
  ...businessLocationRequests,
  ...statementsRequests,
  ...crmRequests,
  ...accountPayableBalanceRequests,
  ...configRequests,
  ...authRequests,
}

export * from './client'
export * from './analytics-service/client'
export * from './payments-service/client'
export * from './aggregator/client'
export * from 'src/api/reporting-service/client'
export * from 'src/api/reporting-service/config-client'
export * from 'src/api/futures-service/client'
