import { Capacitor } from '@capacitor/core'
import { User } from 'configcat-react'
import { Session } from 'next-auth'
import PackageInfo from 'package.json'

/**
 * Returns the standard user object to be used in all ConfigCat evaluations
 * @param session - The NextAuth session
 * @param cookies - The cookies from the request
 * @param custom - Any additional custom properties to add to the user object
 * @returns The ConfigCat user object
 */
export default function getConfigCatUser(
  session: Session | null,
  cookies: Record<string, string>,
  custom: Record<string, any> = {}
) {
  if (!session?.user?.bushelId) return undefined

  const CompanySlug = cookies?.['bushel-web-company']
  const AppInstallationId = cookies?.['bushel-web-installation-id']

  return new User(session.user.bushelId, undefined, undefined, {
    CompanySlug,
    AppInstallationId,
    UserIdSource: 'bushel',
    AppVersion: PackageInfo.version,
    AppPlatform: Capacitor.getPlatform(),
    ...custom,
  })
}
